import { action, redirect } from "@solidjs/router";
import DOMPurify from "dompurify";
import { createId } from "@paralleldrive/cuid2";
import * as articleService from "~/services/articles";
import ArticleForm from "~/components/ArticleForm";

const toSubmit = action(async (formData: FormData) => {
  const title = formData.get("title")?.toString() || "";
  const _content = formData.get("content")?.toString() || "";
  const content = DOMPurify.sanitize(_content);
  const abstract = content.substring(0, 250).replace(/<[^>]*>?/g, "");
  const id = createId();

  await articleService.create({ id, title, abstract, content });
  throw redirect(`/dash/articles/${id}/setting`);
}, "dash/articles/create#FormSubmit");

export default function Submit() {
  return <ArticleForm action={toSubmit} data={() => undefined} />;
}
